import "./CallButton.css";
import phoneIcon from "../../assets/icons/phone-icon.png";
import { useDispatch } from "react-redux";
import { setClearIsFormSended, setIsActive } from "../../redux/ModalSlice/ModalSlice";
import { setBurgerIsClose } from "../../redux/BurgerSlice/BurgerSlice";

const CallButton = () => {
  const dispatch = useDispatch();
  const handleModalOpen = () => {
    dispatch(setIsActive());
    dispatch(setClearIsFormSended());
    dispatch(setBurgerIsClose());
  };

  return (
    <div onClick={handleModalOpen} className="btn-call-v2 mont-m">
        Отримати Розрахунок
    </div>
    // <div onClick={handleModalOpen} className={`btn-call`}>
    //   {/* <div className={`btn-call__ico`}>
    //     <img
    //       src={phoneIcon}
    //       alt="іконка при натискнні на яку відкриється форма зворотнього зв'язку"
    //       className="fas fa-phone-alt"
    //     />
    //   </div> */}
     
    // </div>
  );
};

export default CallButton;
