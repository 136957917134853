import React from 'react'
import { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import Burger from '../Burger/Burger';
import CallButton from '../CallButton/CallButton';
import Cover from '../Cover/Cover';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ModalForm from '../ModalForm/ModalForm';
import './AppRouter.css'

const AppRouter = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
  return (
    <div className="global__container">
        <Header />
        <Cover />
        <main className="main">
            <Outlet />
        </main>
        <Footer />
        <ModalForm />
        <Burger />
        <CallButton />
    </div>
  )
}

export default AppRouter