import { Link } from 'react-router-dom'
import './MainService.css'
import arrow from '../../../../assets/icons/serviceArrow.png'

const MainService = ({ href, src, title, text }) => {
  return (
    <Link to={href} className='main__service'>
        <img className='main__service-image' src={src} alt={`перехід на сторінку ${title}`} />
        <div className="overlay">
            <div className="overlay__texts">
                <img className='overlay__arrow' src={arrow} alt="просто стрілка" />
            <h3 className="main__service-title mont-l">{title}</h3>
            <p className="main__service-text mont-l">{text}</p>
            </div>
        </div>
    </Link>
  )
}

export default MainService