import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setBurgerIsClose } from "../../redux/BurgerSlice/BurgerSlice";
import {
  ABOUTE_ROUTE,
  CONTACTS_ROUTE,
  SERVICE_APARTMENT_REPAIR_ROUTE,
  SERVICE_ARRANGEMENT_ROUTE,
  SERVICE_DESIGN_PROJECT_ROUTE,
} from "../../utils/routes";
import "./Burger.css";

function Burger() {
  const dispatch = useDispatch();
  const isActive = useSelector((state) => state.burger.isBurgerActive);

  const handleBurgerClose = () => {
    dispatch(setBurgerIsClose());
  };

  return (
    <div className={`burger__menu ${isActive ? "active" : ""}`}>
      <div className="burger__overlay">
        <div className="burger__body">
          <Link
            onClick={handleBurgerClose}
            className="burger__link text-title-two"
            to={SERVICE_APARTMENT_REPAIR_ROUTE}
          >
            Ремонт під ключ
          </Link>
          <Link
            onClick={handleBurgerClose}
            className="burger__link text-title-two"
            to={SERVICE_DESIGN_PROJECT_ROUTE}
          >
            Дизайн-проект
          </Link>
          <Link
            onClick={handleBurgerClose}
            className="burger__link text-title-two"
            to={SERVICE_ARRANGEMENT_ROUTE}
          >
            Комплектація
          </Link>
          <Link
            onClick={handleBurgerClose}
            className="burger__link text-title-two"
            to={"/portfolio"}
          >
            Портфоліо
          </Link>
          <Link
            onClick={handleBurgerClose}
            className="burger__link text-title-two"
            to={ABOUTE_ROUTE}
          >
            Про нас
          </Link>
          <Link
            onClick={handleBurgerClose}
            className="burger__link text-title-two"
            to={CONTACTS_ROUTE}
          >
            Контакти
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Burger;
