import './ServicePagePrice.css'
import servicePagePriceImage from '../../../assets/images/servicePagePriceImage.webp'

const ServicePagePrice = ({price, isIndividual}) => {
  return (
    <section className='service__page-price'>
        <img src={servicePagePriceImage} alt="Приклад роботи ТОВ Еврокомфорт" className="service__page-price-image" />
        <div className="service__page-price-wrapper">
            <div className="service__page-price-texts">
            <h2 className="service__page-price-title mont-m">Вартість проекту</h2>
            <p className="service__page-price-text">Для розрахунку вартості проекту нам потрібно знати наступні дані:</p>
            <p className="service__page-price-text">Площа об'єкта</p>
            <p className="service__page-price-text">Бажана стилістика</p>
            <p className="service__page-price-text">Ваші індивідуальні уподобання</p>
            <p className="service__page-price-text">Планований бюджет</p>
            </div>
            <div className={`service__page-price-price ${isIndividual ? "small" : ""} mont-m`}>
                <span className='from'>{`${isIndividual ? "" : "від"}`}</span> {price}{`${isIndividual ? "" : "$ м²"}`}
            </div>
        </div>
    </section>
  )
}

export default ServicePagePrice