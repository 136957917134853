import MainAccordion from "./MainAccordion/MainAccordion";
import "./MainFAQ.css";

const accordionItems = [
  {
    title: "Чому варто обрати саме вашу компанію для ремонту?",
    content: "Наша компанія забезпечує високоякісний ремонт, виконуючи всі роботи в строк і з використанням тільки найкращих матеріалів. Ми гарантуємо індивідуальний підхід до кожного клієнта.",
  },
  {
    title: "Які види ремонту ви пропонуєте?",
    content:
      "Ми пропонуємо повний спектр послуг, включно з капітальним і косметичним ремонтом, дизайном інтер'єру, а також послугами з перепланування приміщень.",
  },
  {
    title: "Яка вартість ваших послуг?",
    content: `Вартість ремонту залежить від обраних вами матеріалів і обсягу робіт.
    Ми завжди готові запропонувати конкурентні ціни та прозорий кошторис.`,
  },
  {
    title: "Скільки часу займає ремонт?",
    content: `Кожен проект для нас унікальний, тож ми не зможемо назвати чіткий термін до проведення бріфінгу. Все залежить від складності проекту та обсягу роботи. В середньому, робота над більшістю проектів триває 1.5-3 місяці. Ми будемо вас інформувати про всі етапи роботи і затверджувати їх з вами.`,
  },
  {
    title: "Чи можу я побачити приклади ваших попередніх робіт?",
    content: `Звичайно! У нас є портфоліо з фотографіями завершених проектів, щоб ви могли оцінити рівень нашої майстерності та стиль.`,
  },
  {
    title: "Чи надаєте ви гарантії на виконані роботи?",
    content: `Так, ми надаємо гарантію на всі види робіт, щоб ви могли бути впевненими в якості та довговічності нашого ремонту.`,
  },
  {
    title: "Якою є ваша політика щодо матеріалів?",
    content: `Ми працюємо тільки з перевіреними постачальниками і можемо запропонувати широкий вибір якісних матеріалів на будь-який бюджет.`,
  },
  {
    title: "Як здійснюється процес узгодження проекту?",
    content: `Ми тісно співпрацюємо з нашими клієнтами на етапі розроблення проекту, узгоджуючи всі деталі, щоб результат повністю відповідав вашим очікуванням.`,
  },
  {
    title: "Що робити, якщо у мене є особливі запити щодо дизайну?",
    content: `Ми завжди готові вислухати ваші ідеї та побажання. Наша команда дизайнерів допоможе реалізувати навіть найбільш незвичайні концепції!`,
  },
  {
    title: "Як я можу з вами зв'язатися?",
    content: `Зателефонуйте нам або заповніть форму на нашому сайті. Ми з радістю відповімо на всі ваші запитання й обговоримо деталі вашого проекту!`,
  },
];

function MainFAQ() {
  return (
    <div className="MainFAQ container">
      <h2 className="main__faq-title">Питання та відповіді</h2>
      <MainAccordion faqItems={accordionItems}/>
    </div>
  );
}

export default MainFAQ;
