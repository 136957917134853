import { SERVICE_APARTMENT_REPAIR_ROUTE, SERVICE_ARRANGEMENT_ROUTE, SERVICE_DESIGN_PROJECT_ROUTE } from '../../../utils/routes'
import './MainServices.css'
import serviceImage1 from '../../../assets/images/mainService1.webp'
import serviceImage2 from '../../../assets/images/mainService2.webp'
import serviceImage3 from '../../../assets/images/mainService3.webp'
import MainService from './MainService/MainService'

const mainServicesArr = [
    {
        title: "Ремонт під ключ",
        text: "Комплексный підхід до ремонту, від брифінгу до готового об’єкта",
        src: serviceImage1,
        href: SERVICE_APARTMENT_REPAIR_ROUTE,
    },
    {
        title: "Дизайн-проект",
        text: "Індивідуальне розроблення інтер'єру, від концепції до візуалізації",
        src: serviceImage2,
        href: SERVICE_DESIGN_PROJECT_ROUTE,
    },
    {
        title: "Комплектація",
        text: "Повне оснащення об'єкта, від меблів до декору",
        src: serviceImage3,
        href: SERVICE_ARRANGEMENT_ROUTE,
    }
]

const MainServices = () => {
  return (
    <section className='main__services'>
        {
            mainServicesArr.map((service, idx) => {
                return (
                    <MainService key={idx} text={service.text} href={service.href} src={service.src} title={service.title} />
                )
            })
        }
    </section>
  )
}

export default MainServices