import { Link } from 'react-router-dom'
import { SERVICE_APARTMENT_REPAIR_ROUTE, SERVICE_ARRANGEMENT_ROUTE, SERVICE_DESIGN_PROJECT_ROUTE } from '../../../utils/routes'
import './MainCategorys.css'
import categoryImage1 from '../../../assets/images/categoryImage1.webp'
import categoryImage2 from '../../../assets/images/categoryImage2.webp'
import categoryImage3 from '../../../assets/images/categoryImage3.webp'

const MainCategorys = () => {
  return (
    <section className='main__categorys'>
        <div className="category">
            <img src={categoryImage1} alt="фото роботи ТОВ Еврокомфорт в категорії Ремонт під ключ" className="category__image" />
            <div className="category-wrapper">
                <h2 className="category-title">Ремонт під ключ</h2>
                <p className="category-text">Наша компанія надає повний спектр послуг з ремонту квартир під ключ. Ми беремо на себе всі етапи - від розробки проекту до чистового оздоблення. Вам не потрібно турбуватися про пошук підрядників або вирішення складних технічних питань. Ми плануємо кожен крок, враховуючи ваші побажання і бюджет, щоб результат повністю відповідав вашим очікуванням. Роботи виконуються строго за договором і у встановлені терміни.</p>
                <Link to={SERVICE_APARTMENT_REPAIR_ROUTE} className="category__link">Детальніше</Link>
            </div>
        </div>
        <div className="category reverse">
        <img src={categoryImage2} alt="фото роботи ТОВ Еврокомфорт в категорії Дизайн-проект" className="category__image second" />
            <div className="category-wrapper reverse">
                <div className="category-wrapper-content">
                <h2 className="category-title">Дизайн-проект</h2>
                <p className="category-text">Розробка дизайн-проекту - це перший крок до створення стильного і функціонального простору. Наші професійні дизайнери врахують усі ваші вподобання, запропонують оптимальні рішення з планування та підбору матеріалів. Ми створюємо унікальні інтер'єри, які не тільки тішать око, а, що найголовніше, проектуються максимально зручними для повсякденного життя. Роботи виконуються строго за договором і у встановлені терміни.</p>
                <Link to={SERVICE_DESIGN_PROJECT_ROUTE} className="category__link">Детальніше</Link>
                </div>
            </div>
        </div>
        <div className="category">
        <img src={categoryImage3} alt="фото роботи ТОВ Еврокомфорт в категорії Комплектація" className="category__image third" />
            <div className="category-wrapper">
                <h2 className="category-title">Комплектація</h2>
                <p className="category-text">Ми пропонуємо послугу повної комплектації квартири: від вибору меблів і освітлення до встановлення побутової техніки. Наші фахівці допоможуть вам створити затишний і комфортний простір, готовий до проживання відразу після завершення ремонту. Ми працюємо з перевіреними постачальниками, гарантуємо високу якість матеріалів і ретельне виконання кожного етапу робіт. З нами ваша квартира стане не тільки красивою, але й функціональною.</p>
                <Link to={SERVICE_ARRANGEMENT_ROUTE} className="category__link">Детальніше</Link>
            </div>
        </div>
    </section>
  )
}

export default MainCategorys