import { Link } from "react-router-dom";
import {
  SERVICE_APARTMENT_REPAIR_ROUTE,
  SERVICE_ARRANGEMENT_ROUTE,
  SERVICE_DESIGN_PROJECT_ROUTE,
} from "../../utils/routes";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__contacts">
          <a
            className="footer__contact"
            href="mailto:Evrocomfort.office@gmail.com
"
          >
            Evrocomfort.office@gmail.com
          </a>
          <a className="footer__contact" href="tel:+380507769223">
            +380 (50) 776 92 23
          </a>
        </div>
          <div className="footer__links">
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/eurocomfort.ua/?igsh=MWgyYmFiZTN2cWJkNQ%3D%3D#" className="footer__link">Instagram</a>
            <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@eurocomfort_kyiv?_t=ZM-8ulVEWbLYec&_r=1" className="footer__link">TikTok</a>
          </div>
      </div>
      <div className="footer__second-wrapper">
        <nav className="footer__nav">
          <Link to={SERVICE_APARTMENT_REPAIR_ROUTE} className="footer__link">
            Ремонт під ключ
          </Link>
          <Link to={SERVICE_DESIGN_PROJECT_ROUTE} className="footer__link">
            Дизайн-проект
          </Link>
          <Link to={SERVICE_ARRANGEMENT_ROUTE} className="footer__link">
            Комплектація
          </Link>
        </nav>
        <div className="footer__copyright">Copyright EuroComfort</div>
      </div>
    </footer>
  );
};

export default Footer;
