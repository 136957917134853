import { teamArr } from '../../utils/teamArr'
import { worksArr } from '../../utils/worksArr'
import MainAbout from './MainAbout/MainAbout'
import MainCategorys from './MainCategorys/MainCategorys'
import MainFAQ from './MainFAQ/MainFAQ'
import MainOurWorks from './MainOurWorks/MainOurWorks'
import './MainPage.css'
import MainServices from './MainServices/MainServices'
import TeamSlider from './TeamSlider/TeamSlider'

const MainPage = () => {
  return (
    <div className='page'>
            <script>{`
       (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NG89XM64')
      `}</script>
        <MainServices />
        <MainOurWorks worksArr={worksArr} />
        <MainCategorys />
        <MainAbout />
        <TeamSlider teamArr={teamArr} />
        <MainFAQ />
        <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NG89XM64"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
    </div>
  )
}

export default MainPage