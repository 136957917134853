import React from "react";
import "./SliderCard.css";

function SliderCard({ src }) {
  return (
    <div className="slider__card">
      <div>
        <img
          src={src}
          alt={`на фото зображення нашої клініки Філада`}
          className="welcome__image"
        />
      </div>
    </div>
  );
}

export default SliderCard;
