import './MainOurWorks.css'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import SliderCard from './SliderCard/SliderCard';

const MainOurWorks = ({worksArr}) => {
    const isMobile = window.innerWidth <= 1199;

  return (
    <section className='main__works-section'>
        <h2 className="main__works-section-title mont-r">Наші роботи</h2>
        <div className="slider__wrapper">
        <Swiper
          slidesPerView={isMobile ? 1 : 5}
          loop={true}
          lazy={true}
          navigation={isMobile ? true : false}
          modules={[Navigation]}
          className="mySwiper"
        >
          {worksArr.map((slide, idx) => (
            <SwiperSlide key={idx}>
              <SliderCard
                src={slide}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        </div>
    </section>
  )
}

export default MainOurWorks