import { galleryArr } from "../../utils/galleryArr";
import "./GalleryPage.css";

const GalleryPage = () => {
  return (
    <div className="page">
      <script>{`
       (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NG89XM64')
      `}</script>
      <h2 className="gallery__page-title">Наше портфоліо</h2>
      <div className="gallery__page-wrapper">
        {galleryArr.map((image, idx) => {
          return (
            <img
              key={idx}
              src={image}
              alt="Єврокомфорт приклад ремонту та дизайну приміщень фото"
              className="gallery__image"
            />
          );
        })}
      </div>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NG89XM64"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
    </div>
  );
};

export default GalleryPage;
