import "./ContactsPage.css";

const ContactsPage = () => {
  return (
    <div className="contacts__page">
            <script>{`
       (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NG89XM64')
      `}</script>
      <div className="contacts__wrapper">
        <div className={`contacts__adress`}>
          <p className={`adress mont-r`}>м. Київ </p>
          <p className={`adress mont-r`}>вул. Богдана Хмельницького, 17/52а</p>
        </div>
        <div className={`contacts__connect`}>
          <a className={`contact__tel mont-r`} href="tel:+380507769223">
          +380 (50) 776 92 23
          </a>
          <a className={`mail mont-r`} href="mailto:Evrocomfort.office@gmail.com">
          Evrocomfort.office@gmail.com</a>
        </div>
        <div className={`contacts__time`}>
          <p className={`time mont-r`}>9:00-18:00</p>
          <p className={`days mont-r`}>ПН-ПТ</p>
        </div>
      </div>
      <section className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2540.745717440851!2d30.513009200000003!3d50.445836899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce581cdea24b%3A0x93e1b5d0bf46b675!2sYevrokomfort!5e0!3m2!1sru!2sua!4v1735302770363!5m2!1sru!2sua"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="карта як нас знайти"
        ></iframe>
      </section>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NG89XM64"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
    </div>
  );
};

export default ContactsPage;
