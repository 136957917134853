import { SERVICE_APARTMENT_REPAIR_ROUTE, SERVICE_ARRANGEMENT_ROUTE, SERVICE_DESIGN_PROJECT_ROUTE } from "./routes";
import servicePhoto1 from '../assets/images/categoryImage1.webp'
import servicePhoto2 from '../assets/images/categoryImage2.webp'
import servicePhoto3 from '../assets/images/categoryImage3.webp'
import servicePhoto4 from '../assets/images/servicePhotoSecond1.webp'
import servicePhoto5 from '../assets/images/servicePhotoSecond2.webp'
import servicePhoto6 from '../assets/images/servicePhotoSecond3.webp'

export const servicePageArr = [
    {
        metaTitle: "Ремонт під ключ в Києві | EuroComfort",
        metaDescription: "Ремонт під ключ від EuroComfort ⭐ Доступна ціна ✔️ Кращі майстри ✔️ Професійний підхід ✔️ Комфорт та якість вашого житла Тел.050-776-92-23",
        titleFist: "Ремонт під ключ",
        textFirst: "Ми беремо на себе всi етапи ремонту вiд початкового планування до фiнального результату. Наша команда забезпечує професiйний пiдхiд до кожної дрiбницi, щоб ви могли насолоджуватись процесом без зайвих турбот. Ми гарантуємо якiсть, дотримання термiнiв i вiдповiднiсть вашим очiкуванням. В процесi роботи ми надаємо детальний план дiй, узгоджуємо кожен етап з вами i забезпечуємо прозорiсть усiх витрат, щоб ви були впевненi у результатi.",
        srcFirst: servicePhoto1,
        titleSecond: "Комплексний пiдхiд",
        textSecond: "Ми забезпечуємо повний спектр послуг: демонтаж, оздоблювальнi роботи, монтаж комунiкацiй та установку обладнання. Завдяки чiткiй органiзацiї процесу ви отримуєте гарантований результат у домовленi термiни. Ми використовуємо сучаснi технологiї та матерiали, що забезпечують довговiчнiсть i високу якiсть виконаних робiт. Наш пiдхiд дозволяє уникнути зайвих витрат часу i ресурсiв, залишаючи вам лише задоволення вiд готового результату.",
        srcSecond: servicePhoto5,
        price: "120",
        path: SERVICE_APARTMENT_REPAIR_ROUTE,
        isIndividual: false,
    },
    {
        metaTitle: "Дизайн-проект під ключ в Києві | EuroComfort",
        metaDescription: "Дизайн-проект від EuroComfort ⭐ Доступна ціна ✔️ Кращі майстри ✔️ Професійний підхід ✔️ Комфорт та якість вашого житла Тел.050-776-92-23",
        titleFist: "Дизайн-проект",
        textFirst: "Наша компанiя надає професiйнi послуги з розробки дизайн-проектiв для квартир i будинкiв. Ми створюємо iндивiдуальнi рiшення, якi поєднують стиль, функцiональнiсть i вашi побажання. Вiд концепцiї до реалiзацiї проекту ми враховуємо кожну деталь, щоб створити простiр, який стане iдеальним для вашого життя. Ми пропонуємо комплексний пiдхiд, який включає аналiз ваших потреб, створення концепцiї та детальне опрацювання кожного елемента дизайну, гарантуючи, що результат перевершить вашi очiкування.",
        srcFirst: servicePhoto2,
        titleSecond: "Стиль i функцiональнiсть",
        textSecond: "Ми працюємо над тим, щоб Ваш iнтер'єр був не лише стильним, але й максимально зручним у використаннi. Нашi дизайнери ретельно пiдбирають матерiали, кольори та елементи декору, створюючи гармонiйний простiр, який вiдповiдає сучасним тенденцiям та вашим потребам. До уваги беруться всі Ваші побажання та очікування по бюджету. Крiм того, ми враховуємо ергономiку примiщення, забезпечуючи комфорт та практичнiсть у кожному аспектi вашого нового простору. ",
        srcSecond: servicePhoto4,
        price: "10",
        path: SERVICE_DESIGN_PROJECT_ROUTE,
        isIndividual: false,
    },
    {
        metaTitle: "Комплектація житла під ключ в Києві | EuroComfort",
        metaDescription: "Комплектація житла від EuroComfort ⭐ Доступна ціна ✔️ Кращі майстри ✔️ Професійний підхід ✔️ Комфорт та якість вашого житла Тел.050-776-92-23",
        titleFist: "Комплектація",
        textFirst: "Нашi фахiвцi допоможуть вам зробити ваш дiм комфортним i стильним. Ми пропонуємо комплекснi рiшення з комплектації житлових i комерцiйних примiщень. Вiд пiдбору меблiв до декору—все для створення затишку та естетики вашого простору. Ми беремо до уваги вашi потреби, стиль життя та бюджет, щоб створити простiр, який буде надихати вас кожного дня i водночас залишатись функцiональним та практичним.",
        srcFirst: servicePhoto3,
        titleSecond: "Дизайн i комфорт",
        textSecond: "Комплектація житла — це мистецтво, яке ми перетворюємо на комфорт для вас. Ми дбайливо пiдбираємо меблi, текстиль та аксесуари, щоб створити гармонiйний i функцiональний простiр, який вiдображатиме вашу iндивiдуальнiсть. Крiм того, ми працюємо над тим, щоб кожен елемент iнтер'єру гармонiйно доповнював загальну концепцiю, забезпечуючи затишок i привабливий вигляд вашого житла.",
        srcSecond: servicePhoto6,
        price: "Індивідуально",
        path: SERVICE_ARRANGEMENT_ROUTE,
        isIndividual: true,
    },
]