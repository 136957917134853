import "./TeamSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import TeamSliderCard from "./TeamSliderCard/TeamSliderCard";
import { useRef, useEffect, useState } from "react";

const TeamSlider = ({ teamArr }) => {
  const isMobile = window.innerWidth <= 1199;
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);

  useEffect(() => {
    if (swiperInstance && prevRef.current && nextRef.current) {
      swiperInstance.params.navigation.prevEl = prevRef.current;
      swiperInstance.params.navigation.nextEl = nextRef.current;
      swiperInstance.navigation.init();
      swiperInstance.navigation.update();
    }
  }, [swiperInstance]);

  

  return (
    <section className="main__team-section">
      <h2 className="main__works-section-title mont-r">Наша команда</h2>
      <div className="team__slider-wrapper">
        <Swiper
          className="mySwiper team-slider"
          slidesPerView={isMobile ? 1 : 5}
          loop={true}
          lazy={true}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}          modules={[Navigation]}
          onSwiper={setSwiperInstance} 
        >
          {teamArr.map((slide, idx) => (
            <SwiperSlide className="team-slide" key={idx}>
              <TeamSliderCard
                title={slide.title}
                jobTitle={slide.jobTitle}
                src={slide.src}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div ref={prevRef} className="swiper-button-prev team-slider-prev"></div>
        <div ref={nextRef} className="swiper-button-next team-slider-next"></div>
      </div>
    </section>
  );
};

export default TeamSlider;
