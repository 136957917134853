import galleryImage1 from "../assets/images/gallery/gallery1.webp";
import galleryImage2 from "../assets/images/gallery/gallery2.webp";
import galleryImage3 from "../assets/images/gallery/gallery3.webp";
import galleryImage4 from "../assets/images/gallery/gallery4.webp";
import galleryImage5 from "../assets/images/gallery/gallery5.webp";
import galleryImage6 from "../assets/images/gallery/gallery6.webp";
import galleryImage7 from "../assets/images/gallery/gallery7.webp";
import galleryImage8 from "../assets/images/gallery/gallery8.webp";
import galleryImage9 from "../assets/images/gallery/gallery9.webp";
import galleryImage10 from "../assets/images/gallery/gallery10.webp";
import galleryImage11 from "../assets/images/gallery/gallery11.webp";
import galleryImage12 from "../assets/images/gallery/gallery12.webp";
import galleryImage13 from "../assets/images/gallery/gallery13.webp";
import galleryImage14 from "../assets/images/gallery/gallery14.webp";
import galleryImage15 from "../assets/images/gallery/gallery15.webp";
import galleryImage16 from "../assets/images/gallery/gallery16.webp";
import galleryImage17 from "../assets/images/gallery/gallery17.webp";
import galleryImage18 from "../assets/images/gallery/gallery18.webp";
import galleryImage19 from "../assets/images/gallery/gallery19.webp";
import galleryImage20 from "../assets/images/gallery/gallery20.webp";

export const galleryArr = [
  galleryImage1,
  galleryImage2,
  galleryImage3,
  galleryImage4,
  galleryImage5,
  galleryImage6,
  galleryImage7,
  galleryImage8,
  galleryImage9,
  galleryImage10,
  galleryImage11,
  galleryImage12,
  galleryImage13,
  galleryImage14,
  galleryImage15,
  galleryImage16,
  galleryImage17,
  galleryImage18,
  galleryImage19,
  galleryImage20,
];
