import { Link } from 'react-router-dom'
import './MainAbout.css'
import mainAboutImage from '../../../assets/images/mainAbout.webp'
import { ABOUTE_ROUTE } from '../../../utils/routes'

const MainAbout = () => {
  return (
    <section className='main__about-section'>
        <div className="main__about">
            <img src={mainAboutImage} alt="на фото кухня яку зробила ТОВ Еврокомфорт" className="main__about-image" />
            <div className="main__about-content">
                <div className="main__about-content-wrapper">
                <h2 className="main__about-content-title">Про нас</h2>
                <p className="main__about-content-text">Ми - команда професіоналів, що спеціалізується на створенні унікальних інтер'єрів і комплексних рішеннях для комплектації житла. Наш підхід ґрунтується на увазі до деталей та індивідуальних потребах кожного клієнта. Завдяки нашому досвіду та професіоналізму ви отримуєте простір, у якому комфорт і стиль поєднуються з функціональністю. Ми цінуємо ваш час і гарантуємо якісний результат в обумовлені терміни.</p>
                <div className="main__about-content-footer">
                    <div className="main__about-content-footer-stats mont-l">{">120"}<span className='main__about-content-footer-text mont-r'>Проектів реалізовано</span></div>
                    <Link to={ABOUTE_ROUTE}  className="main__about-content-footer-link">{"Детальніше"} &#8658;</Link>
                    <Link to={ABOUTE_ROUTE}  className="main__about-content-footer-link-mobile">{"Детальніше"}</Link>
                </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default MainAbout