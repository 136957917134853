import workImage1 from '../assets/images/worksExample1.webp'
import workImage2 from '../assets/images/worksExample2.webp'
import workImage3 from '../assets/images/worksExample3.webp'
import workImage4 from '../assets/images/worksExample4.webp'
import workImage5 from '../assets/images/worksExample5.webp'
import workImage6 from '../assets/images/worksExample6.webp'
import workImage7 from '../assets/images/worksExample7.webp'

export const worksArr = [
    workImage1, workImage2, workImage3, workImage4, workImage5, workImage6, workImage7
]