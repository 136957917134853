import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import AppRouter from "./components/AppRouter/AppRouter";
import AboutPage from "./pages/AboutPage/AboutPage";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import MainPage from "./pages/MainPage/MainPage";
import ServicePage from "./pages/ServicePage/ServicePage";
import { ABOUTE_ROUTE, CONTACTS_ROUTE } from "./utils/routes";
import { servicePageArr } from "./utils/servicePageArr";
import GalleryPage from "./pages/GalleryPage/GalleryPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<AppRouter />}>
            <Route index element={<MainPage />} />
            <Route path={ABOUTE_ROUTE} element={<AboutPage />} />
            <Route path={CONTACTS_ROUTE} element={<ContactsPage />} />
            <Route path={"/portfolio"} element={<GalleryPage />} />
              {
                servicePageArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ServicePage titleFist={page.titleFist} textFirst={page.textFirst} srcFirst={page.srcFirst} titleSecond={page.titleSecond} textSecond={page.textSecond} srcSecond={page.srcSecond} price={page.price} isIndividual={page.isIndividual} metaTitle={page.metaTitle} metaDescription={page.metaDescription} />} />
                  )
                })
              }
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
