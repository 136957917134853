import "./Steps.css";
import React from "react";
import Step from "./Step/Step";

export const stepsArr = [
    {
        number: 1,
        title: "Брифінг та розрахунок",
        description: "Ми починаємо з детальної консультації, щоб зрозуміти ваші побажання. Цей етап дає змогу задати правильний напрямок для всього проекту.",
    },
    {
        number: 2,
        title: "Проектування",
        description: "Наша команда розробляє індивідуальний проект, включно з плануванням, колірними рішеннями і підбором матеріалів.",
    },
    {
        number: 3,
        title: "Затвердження",
        description: "Ви знайомитеся з початковим проектом, і ми вносимо необхідні зміни. Цей етап допомагає досягти повної відповідності вашим очікуванням.",
    },
    {
        number: 4,
        title: "Реалізація та контроль",
        description: "Наші фахівці виконують усі етапи ремонтних робіт, від демонтажу до фінальних штрихів, під контролем виділеного проектного менеджера.",
    },
    {
        number: 5,
        title: "Тестування і перевірка",
        description: "Ми здійснюємо детальну перевірку виконаних робіт на всіх етапах. Це дозволяє гарантувати, що кожна деталь відповідає всім стандартам якості.",
    },
    {
        number: 6,
        title: "Передача об’єкту",
        description: "Додаємо останні штрихи для створення завершеного образу. Проект передається вам готовим до заселення та комфортного проживання.",
    },
]

const Steps = () => {
  return (
    <section id="stages" className="steps__section">
      <h2 className="steps__title mont-m">Етапи роботи з нами</h2>
      <div className="steps__section-wrapper">
        <div className="steps">
          {stepsArr.map((step, idx) => {
            return (
              <Step
                key={idx}
                stepTitle={step.title}
                stepDescription={step.description}
                stepNumber={step.number}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Steps;
