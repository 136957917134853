import "./Cover.css";
import coverImage from "../../assets/images/CoverImage.webp";
import coverImageMobile from "../../assets/images/coverMobile.webp";
import { Link, useLocation } from "react-router-dom";
import {
  SERVICE_APARTMENT_REPAIR_ROUTE,
  SERVICE_ARRANGEMENT_ROUTE,
  SERVICE_DESIGN_PROJECT_ROUTE,
} from "../../utils/routes";
import arrow from "../../assets/icons/arrowRight.png";
import { useDispatch } from "react-redux";
import {
  setClearIsFormSended,
  setIsActive,
} from "../../redux/ModalSlice/ModalSlice";
import { setBurgerIsClose } from "../../redux/BurgerSlice/BurgerSlice";
import { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

const routeTitles = {
  "/apartment-repair/": "Ремонт квартир під ключ в Києві",
  "/design-project/": "Дизайн інтер'єру під ключ в Києві",
  "/arrangement/": "Комплектація квартири в Києві",
};

const Cover = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentTitle =
    routeTitles[location.pathname] || "Дизайн інтер'єру та ремонт під ключ";
  const { pathname } = useLocation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  const isMobile = window.innerWidth <= 1199;

  const handleModalOpen = () => {
    dispatch(setIsActive());
    dispatch(setClearIsFormSended());
    dispatch(setBurgerIsClose());
  };

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls, pathname]);

  return (
    <section className="cover__section">
      <img
        src={isMobile ? coverImageMobile : coverImage}
        alt="Приклад ремонту під ключ від компанії Єврокомфорт"
        className="cover__image"
      />
      <div className="cover__section-wrapper">
        <motion.div
          key={pathname}
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 1.3, delay: 0.2 }}
          className="cover__content"
        >
          <h1 className="cover__title mont-m">{currentTitle}</h1>
          <h2 className="cover__description mont-l">EuroComfort</h2>
          <button onClick={handleModalOpen} className="cover__button">
            Залишити заявку
          </button>
          <nav className="cover__nav-mobile">
            <Link
              className="cover__nav-item mont-l"
              to={SERVICE_APARTMENT_REPAIR_ROUTE}
            >
              Ремонт під ключ
              <img
                src={arrow}
                alt="стрилка переходу на сторінку Ремонт під ключ"
                className="cover__nav-item-arrow"
              />
            </Link>
            <Link
              className="cover__nav-item mont-l"
              to={SERVICE_DESIGN_PROJECT_ROUTE}
            >
              Дизайн-проект
              <img
                src={arrow}
                alt="стрилка переходу на сторінку Ремонт під ключ"
                className="cover__nav-item-arrow"
              />
            </Link>
            <Link
              className="cover__nav-item mont-l"
              to={SERVICE_ARRANGEMENT_ROUTE}
            >
              Комплектація
              <img
                src={arrow}
                alt="стрилка переходу на сторінку Ремонт під ключ"
                className="cover__nav-item-arrow"
              />
            </Link>
          </nav>
        </motion.div>
      </div>
    </section>
  );
};

export default Cover;
