import Hamburger from "hamburger-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  setBurgerIsActive,
  setBurgerIsClose,
} from "../../redux/BurgerSlice/BurgerSlice";
import {
  setClearIsFormSended,
  setIsClose,
} from "../../redux/ModalSlice/ModalSlice";
import {
  ABOUTE_ROUTE,
  CONTACTS_ROUTE,
  SERVICE_APARTMENT_REPAIR_ROUTE,
  SERVICE_ARRANGEMENT_ROUTE,
  SERVICE_DESIGN_PROJECT_ROUTE,
} from "../../utils/routes";
import "./Header.css";
import logo from '../../assets/images/logo.png'

const Header = () => {
  const isActive = useSelector((state) => state.burger.isBurgerActive);
  const [isOpen, setOpen] = useState(isActive);
  const dispatch = useDispatch();

  const handleBurgerClose = () => {
    dispatch(setBurgerIsClose());
  };

  useEffect(() => {
    setOpen(isActive);
  }, [isActive]);

  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__left">
          <Link to={"/"}>
            <img
            onClick={handleBurgerClose}
              src={logo}
              alt="Логотип ТОВ Еврокомфорт"
              className="header__logo"
            />
          </Link>
          <nav className="header__nav-left">
            <Link
              className="header__link-service mont-r"
              to={SERVICE_APARTMENT_REPAIR_ROUTE}
            >
              Ремонт під ключ
            </Link>
            <Link
              className="header__link-service mont-r"
              to={SERVICE_DESIGN_PROJECT_ROUTE}
            >
              Дизайн-проект
            </Link>
            <Link
              className="header__link-service mont-r"
              to={SERVICE_ARRANGEMENT_ROUTE}
            >
              Комплектація
            </Link>
          </nav>
        </div>
        <div className="header__right">
          <nav className="header__nav-right">
            <Link className="header__link mont-r" to={"/portfolio"}>
              Портфоліо
            </Link>
            <Link className="header__link mont-r" to={ABOUTE_ROUTE}>
              Про нас
            </Link>
            <Link className="header__link mont-r" to={CONTACTS_ROUTE}>
              Контакти
            </Link>
          </nav>
          <div className="header__buttons">
            <a className="header__button mont-r" href="tel:+380507769223">
            050-776-92-23
            </a>
            <div className="burger__button">
              <Hamburger
                size={28}
                color="#FFFFFF"
                toggled={isOpen}
                onToggle={(toggled) => {
                  if (toggled) {
                    dispatch(setIsClose());
                    dispatch(setBurgerIsActive());
                    dispatch(setClearIsFormSended());
                    setOpen(true);
                  } else {
                    dispatch(setBurgerIsClose());
                    setOpen(false);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
