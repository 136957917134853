import "./ServicePage.css";
import ServicePagePrice from "./ServicePagePrice/ServicePagePrice";
import Steps from "./Steps/Steps";

const ServicePage = ({
  srcFirst,
  titleFist,
  textFirst,
  titleSecond,
  textSecond,
  srcSecond,
  price,
  isIndividual,
  metaTitle,
  metaDescription,
}) => {
  return (
    <div className="service__page">
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <script>{`
       (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NG89XM64')
      `}</script>
      <div className="service__wrapper">
        <div className="service__first">
          <div className="service__first-texts">
            <h2 className="service__first-title">{titleFist}</h2>
            <p className="service__first-text mont-r">{textFirst}</p>
          </div>
          <img
            src={srcFirst}
            alt={`фото роботи компанії Еврокомфорт у розділі ${titleFist}`}
            className="service__first-photo"
          />
        </div>
        <div className="service__second">
          <div className="service__first-texts">
            <h2 className="service__first-title second mont-l">
              {titleSecond}
            </h2>
            <p className="service__first-text">{textSecond}</p>
          </div>
          <img
            src={srcSecond}
            alt={`фото роботи компанії Еврокомфорт у розділі ${titleFist}`}
            className="service__first-photo"
          />
        </div>
      </div>
      <ServicePagePrice isIndividual={isIndividual} price={price} />
      <Steps />
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NG89XM64"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
    </div>
  );
};

export default ServicePage;
