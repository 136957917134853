import team1 from "../assets/images/team1.webp";
import team2 from "../assets/images/team2.webp";
import team3 from "../assets/images/team3.webp";
import team4 from "../assets/images/team4.webp";


export const teamArr = [
    {
        title: "Катериненко Павло",
        jobTitle: "Керівник",
        src: team1,
    },
    {
        jobTitle: "Дизайнер",
        title: "Корнєєва Мирослава",
        src: team2,
    },
    {
        title: "Петро Линкевич",
        jobTitle: "Прораб",
        src: team3,
    },
    {
        title: "Войнова Олександра",
        jobTitle: "Комплектатор",
        src: team4,
    },
    {
        title: "Катериненко Павло",
        jobTitle: "Керівник",
        src: team1,
    },
    {
        jobTitle: "Дизайнер",
        title: "Корнєєва Мирослава",
        src: team2,
    },
    {
        title: "Петро Линкевич",
        jobTitle: "Прораб",
        src: team3,
    },
    {
        title: "Войнова Олександра",
        jobTitle: "Комплектатор",
        src: team4,
    },
    {
        title: "Катериненко Павло",
        jobTitle: "Керівник",
        src: team1,
    },
    {
        jobTitle: "Дизайнер",
        title: "Корнєєва Мирослава",
        src: team2,
    },
    {
        title: "Петро Линкевич",
        jobTitle: "Прораб",
        src: team3,
    },
    {
        title: "Войнова Олександра",
        jobTitle: "Комплектатор",
        src: team4,
    },
   
]