import React from "react";
import "./TeamSliderCard.css";

function TeamSliderCard({ src, title, jobTitle }) {
    
  return (
    <div className="team-slide__card">
        <img
          src={src}
          alt={`на фото зображення нашої клініки Філада`}
          className="team-slide__card-img"
        />
      <div className="team-slide__card-content">
        <p className="team-slide__card-name mont-r">{title}</p>
        <p className="team-slide__card-job mont-r">{jobTitle}</p>
      </div>
    </div>
  );
}

export default TeamSliderCard;
