import "./AboutPage.css";
import aboutPhoto from "../../assets/images/aboutBigImage.webp";
import aboutWhyUsImage1 from "../../assets/images/aboutWhyUsImage1.webp";
import aboutWhyUsImage2 from "../../assets/images/aboutWhyUsImage2.webp";
import aboutWhyUsImage3 from "../../assets/images/aboutWhyUsImage3.webp";
import aboutWhyUsImage4 from "../../assets/images/aboutWhyUsImage4.webp";

const AboutPage = () => {
  return (
    <div className="about__page">
      <script>{`
       (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NG89XM64')
      `}</script>
      <div className="about__page-wrapper">
        <h2 className="aboute__page-title">Про компанію</h2>
        <div className="aboute__page-border"></div>
        <div className="about__page-about">
          <div className="about__page-about-texts">
            <h3 className="about__page-about-title">
              Ми - команда професіоналів, яка займається ремонтом під ключ. Наша
              мета - перетворити будь-яке приміщення на унікальний і комфортний
              простір, який повністю відповідає вашим вподобанням і потребам.
            </h3>
            <div className="about__page-about-text">
              З моменту першого спілкування з клієнтом і до остаточної здачі
              об'єкта ми прагнемо забезпечити високий рівень сервісу та якості
              на кожному етапі роботи. Ми враховуємо всі побажання і деталі, щоб
              втілити в життя ваші ідеї. Наша команда складається з досвідчених
              дизайнерів, архітекторів і майстрів, які працюють з інноваційними
              технологіями та якісними матеріалами.
            </div>
            <div className="about__page-about-text">
              Ми знаємо, що ремонт - це серйозне вкладення у ваше майбутнє і
              комфорт. Тому наш підхід будується на прозорості та надійності. Ми
              уважно плануємо кожен крок, обговорюємо всі нюанси і дотримуємося
              заздалегідь затвердженого графіка, щоб ви могли бути впевнені у
              своєчасному та якісному результаті.
            </div>
          </div>
          <img
            src={aboutPhoto}
            alt="фото роботи тов Еврокомфорт"
            className="about__page-about-image"
          />
        </div>
        <div className="about__page-why-us">
          <h2 className="about__page-why-us-title">Наші переваги</h2>
          <div className="about__page-why-us-wrapper">
            <div className="about__page-why-us-item">
              <h3 className="about__page-why-us-item-title">Професіоналізм</h3>
              <img
                src={aboutWhyUsImage1}
                alt="Приклад роботи ТОВ Еврокомфорт"
                className="about__page-why-us-item-image"
              />
            </div>
            <div className="about__page-why-us-item">
              <h3 className="about__page-why-us-item-title">
                Комплексний підхід
              </h3>
              <img
                src={aboutWhyUsImage2}
                alt="Приклад роботи ТОВ Еврокомфорт"
                className="about__page-why-us-item-image"
              />
            </div>
            <div className="about__page-why-us-item">
              <h3 className="about__page-why-us-item-title">
                Індивідуальний дизайн
              </h3>
              <img
                src={aboutWhyUsImage3}
                alt="Приклад роботи ТОВ Еврокомфорт"
                className="about__page-why-us-item-image"
              />
            </div>
            <div className="about__page-why-us-item">
              <h3 className="about__page-why-us-item-title">
                Якісні матеріали
              </h3>
              <img
                src={aboutWhyUsImage4}
                alt="Приклад роботи ТОВ Еврокомфорт"
                className="about__page-why-us-item-image"
              />
            </div>
          </div>
        </div>
      </div>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NG89XM64"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
    </div>
  );
};

export default AboutPage;
